import classNames from 'classnames'
import { graphql, Link, StaticQuery, } from 'gatsby'
import React from 'react'

interface IHeaderRenderProps {
  site: {
    siteMetadata: {
      title: string
      by: string
    }
  }
  logo: {
    publicURL: string
  }
}

interface IHeaderState {
  isNavBurgerActive: boolean
}

const query = graphql`query HeaderQuery {
  site {
    siteMetadata {
      title
      by
    }
  }
  logo: file(relativePath: {eq: "logo.svg"}) {
    publicURL
  }
}`

export default class Header extends React.Component<{}, IHeaderState> {
  state = {
    isNavBurgerActive: false,
  }

  onNavBurgerClick = () => {
    const { isNavBurgerActive } = this.state
    this.setState({
      isNavBurgerActive: !isNavBurgerActive,
    })
  }

  render() {
    const render = ({ site: { siteMetadata: meta }, logo }: IHeaderRenderProps) => {
      const { isNavBurgerActive } = this.state
      return (
        <header className="Header navbar is-fixed-top has-shadow"
                role="navigation"
                aria-label="main navigation">
          <div className="container">
            <div className="navbar-brand">
              <Link className="navbar-item is-brand-name" to="/">
                <img src={logo.publicURL}
                     className="image is-48x48"
                     alt="Homepage" />
                <span className="title">
                  {meta.title}{' '}
                  <span className="is-sr-only">di {meta.by}</span>
                </span>
              </Link>
              <a className={classNames('navbar-burger', { 'is-active': isNavBurgerActive })}
                 onClick={this.onNavBurgerClick}
                 role="button"
                 aria-label="menu"
                 aria-expanded={isNavBurgerActive}>
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </a>
            </div>
            <nav className={classNames('navbar-menu', { 'is-active': isNavBurgerActive })}>
              <div className="navbar-end">
                <Link className="navbar-item"
                      activeClassName="is-active"
                      to="/">
                  Azienda
                </Link>
                <span className="navbar-item is-hidden-touch" aria-hidden="true">|</span>
                {/*<Link className="navbar-item"*/}
                {/*activeClassName="is-active"*/}
                {/*to="/#">*/}
                {/*Progetti*/}
                {/*</Link>*/}
                {/*<span className="navbar-item is-hidden-touch" aria-hidden="true">|</span>*/}
                {/*<Link className="navbar-item"*/}
                {/*activeClassName="is-active"*/}
                {/*to="/#">*/}
                {/*News*/}
                {/*</Link>*/}
                {/*<span className="navbar-item is-hidden-touch" aria-hidden="true">|</span>*/}
                <Link className="navbar-item"
                      activeClassName="is-active"
                      to="/contatti">
                  Contatti
                </Link>
              </div>
            </nav>
          </div>
        </header>
      )
    }

    return (
      <StaticQuery query={query} render={render} />
    )
  }
}
