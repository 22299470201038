import { graphql, StaticQuery } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

interface ISEOTagsProps {
  meta?: {
    description?: string
  }
}

interface ISEOTagsRenderProps {
  site: {
    siteMetadata: {
      title: string
      by: string
      description: string
      siteUrl: string
    }
  }
  logoPng: {
    childImageSharp: {
      original: {
        src: string
        width: number
        height: number
      }
    }
  }
  logoSvg: {
    publicURL: string
  }
  logoTextPng: {
    childImageSharp: {
      original: {
        src: string
        width: number
        height: number
      }
    }
  }
}

const query = graphql`query SEOTagsQuery {
  site {
    siteMetadata {
      title
      by
      description
      siteUrl
    }
  }
  logoPng: file(relativePath:{ eq: "logo.png"}) {
    childImageSharp {
      original {
        src
        width
        height
      }
    }
  }
  logoSvg: file(relativePath:{ eq: "logo.svg"}) {
    publicURL
  }
  logoTextPng: file(relativePath:{ eq: "logo_text.png"}) {
    childImageSharp {
      original {
        src
        width
        height
      }
    }
  }
}`

export default class SEOTags extends React.Component<ISEOTagsProps> {
  // @see http://ogp.me
  // @see https://developer.twitter.com/en/docs/tweets/optimize-with-cards/overview/summary

  render() {

    const render = (data: ISEOTagsRenderProps) => {
      const {
        site: { siteMetadata },
        logoSvg,
        logoPng: { childImageSharp: { original: logoPng } },
        logoTextPng: { childImageSharp: { original: logoTextPng } },
      } = data
      const meta = { ...siteMetadata, ...this.props.meta }
      return (
        <Helmet>
          {/* Open Graph tags */}
          <html prefix="og: http://ogp.me/ns#" />
          <meta property="og:type" content="website" />
          <meta property="og:locale" content="it_IT" />
          <meta property="og:url" content={meta.siteUrl} />
          <meta property="og:title" content={meta.title} />
          <meta property="og:site_name" content={meta.title} />
          <meta property="og:description" content={meta.description} />

          <meta property="og:image"
                content={meta.siteUrl + logoPng.src} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content={`${logoPng.width}`} />
          <meta property="og:image:height" content={`${logoPng.height}`} />
          <meta property="og:image:alt" content={`Logo di ${meta.title}`} />

          <meta property="og:image"
                content={meta.siteUrl + logoSvg.publicURL} />
          <meta property="og:image:type" content="image/svg+xml" />
          <meta property="og:image:alt" content={`Logo di ${meta.title}`} />

          <meta property="og:image"
                content={meta.siteUrl + logoTextPng.src} />
          <meta property="og:image:type" content="image/png" />
          <meta property="og:image:width" content={`${logoTextPng.width}`} />
          <meta property="og:image:height" content={`${logoTextPng.height}`} />
          <meta property="og:image:alt" content={`Logo di ${meta.title}`} />

          {/* Twitter Card tags */}
          <meta name="twitter:card" content="summary" />
        </Helmet>
      )
    }

    return (
      <StaticQuery query={query} render={render} />
    )
  }
}
