import { graphql, StaticQuery } from 'gatsby'
import React from 'react'

interface IAddressProps {
  format: 'address' | 'inline'
  replaceNbsp: boolean
}

interface IAddress {
  country: string
  locality: string
  region: string
  postalCode: string
  streetAddress: string
}

interface IAddressRenderProps {
  site: {
    siteMetadata: {
      address: IAddress
    }
  }
}

const query = graphql`query AddressQuery {
  site {
    siteMetadata {
      address {
        country
        locality
        region
        postalCode
        streetAddress
      }
    }
  }
}`

export default class Address extends React.Component<IAddressProps> {
  static defaultProps: Partial<IAddressProps> = {
    format: 'address',
    replaceNbsp: true,
  }

  normalize(text: string): string {
    if (this.props.replaceNbsp) {
      // https://stackoverflow.com/a/37909363/3616951
      text = text.replace(/ /g, '\u00A0')
    }

    return text
  }

  render() {
    const { format, replaceNbsp, ...htmlProps } = this.props

    const addressRender = (address: IAddress) => (
      <address {...htmlProps}>
        {address.streetAddress} <br />
        {address.postalCode} {address.locality} ({address.region}), {address.country}
      </address>
    )

    const inlineRender = (address: IAddress) => (
      <span {...htmlProps}>
        {address.streetAddress} {address.postalCode} {address.locality} {' '}
        ({address.region}), {address.country}
      </span>
    )

    const render = ({ site: { siteMetadata: meta }}: IAddressRenderProps) => {
      const address: IAddress = {
        region: this.normalize(meta.address.region),
        country: this.normalize(meta.address.country),
        locality: this.normalize(meta.address.locality),
        postalCode: this.normalize(meta.address.postalCode),
        streetAddress: this.normalize(meta.address.streetAddress),
      }

      switch (format) {
        case 'inline':
          return inlineRender(address)
        default:
          return addressRender(address)
      }
    }

    return (
      <StaticQuery query={query} render={render} />
    )
  }
}
