import { graphql, StaticQuery, } from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

import './all.scss'
import Footer from './layout/Footer'
import GDPR from './layout/GDPR'
import Header from './layout/Header'
import JsonLD from './layout/JsonLD'
import SEOTags from './layout/SEOTags'

interface ILayoutProps {
  children: React.ReactNode
  meta?: {
    title?: string
    description?: string
  }
}

interface ILayoutRenderProps {
  site: {
    siteMetadata: {
      title: string
      by: string
      subtitle: string
      description: string
      keywords: string
    }
  }
}

const query = graphql`query SiteMetaQuery {
  site {
    siteMetadata {
      title
      by
      subtitle
      description
      keywords
    }
  }
}`

export default class Layout extends React.Component<ILayoutProps, {}> {
  render() {

    const render = ({ site: { siteMetadata } }: ILayoutRenderProps) => {
      const { children } = this.props
      const meta = { ...siteMetadata, ...this.props.meta }
      return (
        <>
          <Helmet>
            <html lang="it" />
            <body className="has-navbar-fixed-top" />

            <title>{meta.title} | {meta.title} di {meta.by}</title>

            <meta name="description" content={meta.description} />
            <meta name="keywords" content={meta.keywords} />

            <script async type="text/javascript" src="https://plausible.io/js/analytics.js" />
          </Helmet>

          <SEOTags meta={meta}/>
          <JsonLD />

          <GDPR />

          <Header />
          <main className="site-content is-fullheight-with-navbar">{children}</main>
          <Footer />
        </>
      )
    }

    return (
      <StaticQuery query={query} render={render} />
    )
  }
}
