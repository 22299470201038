import { graphql, Link, StaticQuery, } from 'gatsby'
import React from 'react'
import Address from '../meta/Address'

interface IFooterRenderProps {
  site: {
    siteMetadata: {
      address: {
        country: string
        locality: string
        region: string
        postalCode: string
        streetAddress: string
      },
      piva: string
      cf: string
      rea: string
      contatti: {
        tel: string
        email: string
        pec: string
      }
    }
  }
}

const query = graphql`query FooterQuery {
  site {
    siteMetadata {
      address {
        country
        locality
        region
        postalCode
        streetAddress
      }
      piva
      cf
      rea
      contatti {
        tel
        email
        pec
      }
    }
  }
}`

export default class Footer extends React.Component<{}, {}> {
  render() {
    const render = ({ site: { siteMetadata: meta } }: IFooterRenderProps) => (
      <footer className="footer">
        <div className="container">
          <div className="columns">
            <div className="column">
              <h4 className="title">Dove siamo</h4>
              <Address aria-label="Il nostro indirizzo" />
              <div>
                <p>
                  <span aria-label="Partita IVA">p.iva</span>: <span>{meta.piva}</span>
                </p>
                <p>
                  <span aria-label="Codice Fiscale">c.f.</span>: <span>{meta.cf}</span>
                </p>
                <p>
                  <span aria-label="Registro delle imprese REA">reg. imprese REA</span>:{' '}
                  <span>{meta.rea}</span>
                </p>
              </div>
            </div>
            <div className="column">
              <h4 className="title">Contatti</h4>
              <div>
                <p>
                  tel:&nbsp;
                  <a href={`tel:${meta.contatti.tel.replace(/\s+/g, '')
                    .replace(/[()]/g, '')}`}>
                    {meta.contatti.tel}
                  </a>
                </p>
                <p>
                  email:&nbsp;
                  <a href={`mailto:${meta.contatti.email}`}>
                    {meta.contatti.email}
                  </a>
                </p>
                <p>
                  pec:&nbsp;
                  <a href={`mailto:${meta.contatti.pec}`}>
                    {meta.contatti.pec}
                  </a>
                </p>
              </div>
            </div>
            <div className="column">
              <h4 className="title">Sitemap</h4>
              <ul>
                <li><Link to="/">Azienda</Link></li>
                {/*<li><Link to="/#">Progetti</Link></li>*/}
                {/*<li><Link to="/#">News</Link></li>*/}
                <li><Link to="/contatti">Contatti</Link></li>
              </ul>
            </div>
            <div className="column">
              <p>
                <Link to="/privacy">Privacy & Cookie policy</Link>
              </p>
              <p>
                <small>
                  Sito realizzato da <a href="https://andsala.me?utm_source=gdtecnologie&utm_medium=web"
                                        target="_blank" rel="noopener">Andrea Salamone</a>
                </small>
              </p>
            </div>
          </div>
        </div>
      </footer>
    )

    return (
      <StaticQuery query={query} render={render} />
    )
  }
}
