import { Link } from 'gatsby'
import Cookies from 'js-cookie'
import React from 'react'
import posed, { PoseGroup } from 'react-pose'

import { analyticsOptin, analyticsOptout } from '../privacy/PrivacyContent'

const COOKIE_NAME = '_gdpr'

const GDPRBanner = posed.div({
  enter: {
    y: 0,
    delay: 1000,
    transition: { ease: 'easeOut' },
  },
  exit: {
    y: '100vh',
    transition: { ease: 'easeOut' },
  },
})

interface IGDPRProps {
  onAccept?: () => any
  onDecline?: () => any
}

interface IGDPRState {
  showBanner: boolean
}

export default class GDPR extends React.Component<IGDPRProps, IGDPRState> {
  public static defaultProps: Partial<IGDPRProps> = {
    onAccept: () => {
    },
    onDecline: () => {
    },
  }

  state = {
    showBanner: Cookies.get(COOKIE_NAME) !== 'yes',
  }

  render() {
    const { showBanner } = this.state
    return (
      <PoseGroup animateOnMount>
        {showBanner && (
          <GDPRBanner className="GDPR notification is-primary" key="banner">
            <button className="delete"
                    type="button"
                    title="Accetta i cookie"
                    onClick={this.onAccept} />
            Questo sito utilizza i cookie per migliorare l&rsquo;esperienza.
            Per saperne di più consulta la <Link to="/privacy">cookie policy</Link>.<br />
            <small>Chiudendo questo banner accetti l&rsquo;utilizzo dei cookie.</small>

            <div className="buttons is-right">
              <button className="button is-primary is-small"
                      type="button"
                      onClick={this.onDecline}>
                Rifiuta
              </button>
              <button className="button is-primary is-inverted is-small"
                      type="button"
                      onClick={this.onAccept}>
                Accetta
              </button>
            </div>
          </GDPRBanner>
        )}
      </PoseGroup>
    )
  }

  private onDecline = () => {
    this.hideBanner()
    Cookies.remove(COOKIE_NAME)
    analyticsOptout()
    const { onDecline } = this.props
    if (onDecline) {
      onDecline()
    }
  }

  private onAccept = () => {
    this.hideBanner()
    Cookies.set(COOKIE_NAME, 'yes', { expires: 365 })
    analyticsOptin()
    const { onAccept } = this.props
    if (onAccept) {
      onAccept()
    }
  }

  private hideBanner = () => {
    this.setState({ showBanner: false })
  }
}
