import { graphql, StaticQuery, } from 'gatsby'
import Cookies from 'js-cookie'
import React from 'react'
import { FaCheck } from 'react-icons/fa'
import Address from '../meta/Address'

export function analyticsOptout() {
  const disableStr: string = (window as any).disableStr
  if (disableStr) {
    Cookies.set(disableStr, 'true', { expires: 365 * 10 });
    (window as any).disableStr = !0
  }
}

export function analyticsOptin() {
  const disableStr: string = (window as any).disableStr
  if (disableStr) {
    Cookies.remove(disableStr);
    (window as any).disableStr = false
  }
}

interface IPrivacyContentProps {
  isEmbed?: boolean
}

interface IPrivacyContentState {
  doneGAOptOut: boolean
}

interface IPrivacyContentRenderProps {
  site: {
    siteMetadata: {
      title: string
      by: string
      piva: string
      contatti: {
        email: string
        pec: string
      }
      address: {
        country: string
        locality: string
        region: string
        postalCode: string
        streetAddress: string
      }
    }
  }
}

const query = graphql`query PrivacyContentQuery {
  site {
    siteMetadata {
      title
      by
      piva
      contatti {
        email
        pec
      }
      address {
        country
        locality
        region
        postalCode
        streetAddress
      }
    }
  }
}`

export default class PrivacyContent extends React.Component<IPrivacyContentProps, IPrivacyContentState> {
  private static defaultProps: Partial<IPrivacyContentProps> = {
    isEmbed: false,
  }

  state = {
    doneGAOptOut: false
  }

  render() {
    const { doneGAOptOut } = this.state
    const { isEmbed } = this.props
    const blankExternalLink = isEmbed ? {
      target: '_blank',
      rel: 'noopener',
    } : {}

    const render = ({ site: { siteMetadata: meta } }: IPrivacyContentRenderProps) => (
      <div className="PrivacyContent content">
        <h1 id="privacy">Privacy & Cookie policy</h1>

        <p>
          Informativa resa ai sensi del Decreto Legislativo 196/2003 e del Regolamento (UE) 2016/679 del Parlamento
          Europeo e del Consiglio del 27 Aprile 2016 (GDPR).
        </p>

        <h2>Titolare del trattamento</h2>
        <p>
          Il titolare del trattamento dati è {meta.title} di {meta.by} con sede legale in <Address format="inline" />
          {' '} P.Iva {meta.piva}.
          <br />
          Per l’esercizio dei propri diritti, l’interessato può inviare una email
          a {meta.contatti.email} o {meta.contatti.pec}.
        </p>

        <h2>Luogo di trattamento dei dati</h2>
        <p>
          I trattamenti connessi ai servizi web offerti da questo sito sono effettuati presso la sede del Titolare del
          trattamento e sono curati solo da personale autorizzato al trattamento.
        </p>

        <h2>Tipologia di dati trattati</h2>
        <h3>Dati di navigazione</h3>
        <p>
          I sistemi informatici e le procedure software preposte al funzionamento di questo sito web acquisiscono, nel
          corso del loro normale esercizio, alcuni dati personali la cui trasmissione è implicita nell’uso dei
          protocolli di comunicazione di Internet. Si tratta di informazioni che non sono raccolte per essere associate
          a interessati identificati, ma che per loro stessa natura potrebbero, attraverso elaborazioni ed associazioni
          con dati detenuti da terzi, permettere di identificare gli utenti.
        </p>

        <p>
          In questa categoria di dati rientrano gli indirizzi IP o i nomi a dominio dei computer utilizzati dagli utenti
          che si connettono al sito, gli indirizzi in notazione URI (Uniform Resource Identifier) delle risorse
          richieste, l’orario della richiesta, il metodo utilizzato nel sottoporre la richiesta al server, la dimensione
          del file ottenuto in risposta, il codice numerico indicante lo stato della risposta data dal server (buon
          fine, errore, ecc.) ed altri parametri relativi al sistema operativo e all’ambiente informatico dell’utente.
        </p>

        <p>
          Saranno raccolti i dati di navigazione esclusivamente nell’interesse legittimo di permettere all’utente la
          fruizione dei contenuti pubblicati sui siti Internet del Titolare ed una loro corretta amministrazione e
          gestione.
        </p>

        <h3>Cookie</h3>
        <p>
          Per maggiori informazioni sull&rsquo;uso dei cookie da parte di questo sito visitare la {' '}
          <a href="#cookies">sezione dedicata</a>.
        </p>

        <h3>Dati forniti volontariamente dall&rsquo;utente</h3>
        <p>
          L&rsquo;invio facoltativo, esplicito e volontario di posta elettronica, messaggi o telefonate agli indirizzi
          e-mail con estensione &quot;@gdtecnologie.it&quot; e numeri di telefono indicati su questo sito o l&rsquo;uso
          del form di contatto presente in questo sito comporta per sua stessa natura la successiva acquisizione
          dell’indirizzo del mittente, necessario per rispondere alle richieste, nonché degli eventuali altri dati
          personali inseriti nella missiva. Le basi giuridiche del trattamento sono, pertanto, il legittimo interesse
          del Titolare di rispondere alle comunicazioni pervenute o la necessità di evadere richieste precontrattuali
          avanzate dall’interessato. I dati saranno conservati per il tempo necessario a soddisfare eventuali richieste
          del mittente o questioni ivi sottoposte al Titolare e, comunque, per il tempo imposto da specifiche
          disposizioni di legge. Il mittente ha comunque, bene ricordarlo, il diritto di chiedere la cancellazione degli
          stessi secondo le modalità, condizioni e limiti previsti dall’art. 17 del GDPR.
        </p>

        <p>
          Invitiamo i nostri utenti a non inviare nelle loro comunicazioni nominativi o altri dati personali di soggetti
          terzi che non siano strettamente necessari; ben più opportuno appare, invece, l&rsquo;uso di nomi di fantasia.
        </p>

        <h2>Facoltatività del conferimento dei dati</h2>
        <p>
          A parte quanto specificato per i dati di navigazione, l&rsquo;utente è libero di fornire spontaneamente dati
          personali per richiedere informazioni o servizi. Il loro mancato conferimento può comportare
          l&rsquo;impossibilità di ottenere quanto richiesto.
        </p>

        <h2>Modalità del trattamento</h2>
        <p>
          I dati personali sono trattati in formato elettronico, per il tempo necessario a conseguire gli scopi per cui
          sono stati raccolti. Specifiche misure di sicurezza sono osservate per prevenire la perdita dei dati, usi
          illeciti o non corretti ed accessi non autorizzati.
        </p>

        <h2>Diritti degli interessati</h2>
        <p>
          Ai sensi degli articoli 15 e seguenti del GDPR, l&rsquo;utente ha il diritto di chiedere in qualunque momento,
          l&rsquo;accesso ai suoi dati personali, la rettifica o la cancellazione degli stessi, la limitazione del
          trattamento nei casi previsti dall&rsquo;art. 18 del Regolamento, ottenere in un formato strutturato, di uso
          comune e leggibile da dispositivo automatico i dati che lo riguardano, nei casi previsti dall&rsquo;art. 20
          del GDPR. In ogni momento, l&rsquo;utente può revocare ex art. 7 del GDPR il consenso prestato (laddove il
          consenso sia stato richiesto); proporre reclamo all&rsquo;autorità di controllo competente ex art. 77 del
          GDPR (
          <a href="http://www.garanteprivacy.it/" {...blankExternalLink}>
            Garante per la Protezione dei Dati Personali
          </a>
          ), qualora ritenga che il trattamento dei suoi dati sia contrario alla normativa in vigore.
        </p>

        <p>
          L&rsquo;utente può, inoltre, formulare una richiesta di opposizione al trattamento dei suoi dati personali ex
          art. 21 del GDPR nella quale dare evidenza delle ragioni che giustifichino l&rsquo;opposizione: il Titolare si
          riserva di valutare l&rsquo;istanza, che non verrebbe accettata in caso di esistenza di motivi legittimi
          cogenti per procedere al trattamento che prevalgano sugli interessi, diritti e libertà dell&rsquo;utente.
        </p>

        <p>
          Le richieste inerenti l&rsquo;esercizio dei summenzionati diritti devono essere rivolte all&rsquo;attenzione
          del titolare del trattamento dati di {meta.title} con sede
          in <Address format="inline" /> oppure all&rsquo;indirizzo e-mail: {meta.contatti.email}.
        </p>

        <h2>Aggiornamenti della presente Privacy e Cookie Policy</h2>
        <p>
          Le notizie qui rese possono essere soggette a revisione in seguito a:
        </p>
        <ul>
          <li>
            modifiche della normativa di privacy, per gli aspetti qui di interesse;
          </li>
          <li>
            implementazioni tecnologiche del sito che impattino sulle attuali modalità di trattamento;
          </li>
          <li>
            variazioni organizzative nella struttura di privacy del Titolare che possano interessare l&rsquo;utente.
          </li>
        </ul>

        <p>
          Gli utenti sono cortesemente invitati a visitare periodicamente la presente Policy di modo da essere
          costantemente aggiornati sulle caratteristiche del trattamento.
        </p>

        <h2 id="cookies">Cookies</h2>
        <p>
          Con questa informativa si chiariscono le modalità d&rsquo;uso dei cookie o altri strumenti per
          l&rsquo;archiviazione di dati personali (da qui in poi &quot;cookie&quot;) sul presente sito.
        </p>

        <h3>Cosa sono i cookie?</h3>
        <p>
          I cookie sono piccoli file di testo salvati dal browser sul dispositivo del visitatore del sito. Servono ad
          archiviare informazioni o file relativi alle immagini, come ad esempio i pixel. Se il visitatore naviga sul
          sito più volte usando lo stesso dispositivo, le informazioni salvate nei cookie saranno immediatamente
          trasmesse al nostro sito, &quot;Cookie di prima parte&quot;, oppure a un altro sito, nel caso siano stati
          attivati i &quot;Cookie di terza parte&quot;.
        </p>

        <p>
          Attraverso questo scambio d&rsquo;informazioni, il sito riconosce che un dato visitatore ha già effettuato
          l&rsquo;accesso alle presenti pagine web usando lo stesso browser e dispositivo. Utilizziamo queste
          informazioni per permettere al visitatore di visitare il sito coerentemente con le preferenze di navigazione
          espresse in precedenza.
        </p>

        <p>
          Solo i cookie vengono riconosciuti dal dispositivo del visitatore. I dati personali sono raccolti dietro
          esplicito consenso o solo se strettamente necessario al funzionamento del sito e all&rsquo;espletamento dei
          servizi richiesti dal visitatore.
        </p>

        <h3>Consenso all&rsquo;uso dei cookie</h3>
        <p>
          I cookie che non sono strettamente necessari al funzionamento del sito, sono usati solo dietro esplicito
          consenso del visitatore. Tale consenso viene prestato attraverso l&rsquo;accettazione attraverso
          l&rsquo;apposito banner informativo sui cookie.
        </p>

        <p>
          Al punto &quot;Disattivazione ed eliminazione di tutti i cookie&quot; della presente informativa, è spiegato
          come disattivare o eliminare i cookie in generale, inclusi i cookie necessari, attraverso le impostazioni del
          browser.
        </p>

        <h3>Categorie dei cookie</h3>
        <p>
          In base al loro scopo e alla loro funzione, abbiamo suddiviso i cookie attivi sul nostro sito nelle seguenti
          categorie:
        </p>

        <ul>
          <li>Cookie strettamente necessari;</li>
          {/*<li>Cookie funzionali;</li>*/}
          <li>Cookie statistici.</li>
          {/* <li>
            Cookie pubblicitari / Cookie di terza parte / Cookie richiedenti il consenso
          </li> */}
        </ul>

        <h4>Cookie strettamente necessari</h4>
        <p>
          I cookie strettamente necessari garantiscono il corretto funzionamento del sito e l’offerta del nostro
          servizio così come previsto dalle condizioni contrattuali.
        </p>

        <p>
          Sul nostro sito sono attivi i seguenti cookie essenziali:
        </p>

        <table>
          <colgroup>
            <col />
            <col />
            <col className="not-break" />
          </colgroup>
          <thead>
            <tr>
              <td>Nome</td>
              <td>Scopo</td>
              <td>Durata</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>_gdpr</code>
              </td>
              <td>
                Impedisce che il banner sui cookie venga mostrato nuovamente ad un utente che ha accettato
                l&rsquo;informativa.
              </td>
              <td>365 giorni</td>
            </tr>
            <tr>
              <td>
                <code>ga-disable-GACODE</code>
              </td>
              <td>
                Disabilita Google Analytics secondo la scelta dell&rsquo;utente.
              </td>
              <td>10 anni</td>
            </tr>
          </tbody>
        </table>

        <p>
          Il nostro sito fa uso del servizio <a href="https://developers.google.com/recaptcha/">reCAPTCHA v2</a> per
          prevenire l'uso del form pubblico di contatto per fini di spam.
        </p>

        <p>
          L’uso dei cookie strettamente necessari sul nostro sito è possibile anche senza il consenso del visitatore,
          per questa ragione non possono essere attivati o disattivati singolarmente. Il consenso è invece
          indispensabile per cookie funzionali, statistici e pubblicitari. Il visitatore può comunque, in ogni momento,
          disattivare o eliminare qualsiasi cookie usando le impostazioni del browser (vedi il seguente punto
          "Disattivazione ed eliminazione di tutti i cookie").
        </p>

        <p>
          La base giuridica per l’uso dei cookie di prima parte è il legittimo interesse all’analisi, ottimizzazione e
          rimuneratività del nostro sito e dei nostri servizi, nel rispetto dell’art. Art. 6 (1) (f) del GDPR.
        </p>


        {/*<h4>Cookie funzionali</h4>
        <p>
          I cookie funzionali permettono al nostro sito di salvare informazioni già fornite dal visitatore, in modo da
          poter offrire un servizio personalizzato e basato proprio su questo tipo di dati. I cookie funzionali
          raccolgono e archiviano informazioni in forma anonima.
        </p>

        <p>
          La base giuridica per l&rsquo;uso dei cookie funzionali è il legittimo interesse all&rsquo;analisi,
          ottimizzazione e rimuneratività del nostro sito e dei nostri servizi, nel rispetto dell&rsquo;art. Art. 6 (1)
          (f) del GDPR.
        </p>*/}

        <h4>Cookie statistici</h4>
        <p>
          Per migliorare l&rsquo;aspetto, i contenuti e il funzionamento del nostro sito, raccogliamo informazioni sui
          nostri visitatori attraverso i cookie statistici. Questi cookie ci aiutano a individuare quali pagine sono
          maggiormente visualizzate e quali contenuti destano più interesse. In particolare, controlliamo il numero
          delle visite alle pagine e alle sottopagine, il tempo speso sul sito, l&rsquo;ordine in cui vengono visitate
          le pagine, quali parole chiave sono state impiegate per trovare il sito e anche il paese, la regione, la città
          dalle quali i visitatori accedono alle nostre pagine web. Basandoci su questi dati, possiamo adattare i
          contenuti del sito ai bisogni dei visitatori e ottimizzare la nostra offerta. L&rsquo;indirizzo IP del
          computer, trasmessoci per ragioni tecniche, viene automaticamente cifrato, impedendoci così di conoscere
          l&rsquo;identità del visitatore.
        </p>

        <p>
          Sul nostro sito sono attivi i seguenti cookie statistici:
        </p>

        <table>
          <colgroup>
            <col />
            <col />
            <col className="not-break" />
          </colgroup>
          <thead>
            <tr>
              <td>Nome</td>
              <td>Scopo</td>
              <td>Durata</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <code>_gat</code>
              </td>
              <td>
                Usato per regolare il tasso delle richieste.
              </td>
              <td>1 minuto</td>
            </tr>
            <tr>
              <td>
                <code>_gid</code>
              </td>
              <td>
                Permette di distinguere l&rsquo;utente.
              </td>
              <td>24 ore</td>
            </tr>
            <tr>
              <td>
                <code>_ga</code>
              </td>
              <td>
                Permette di distinguere l&rsquo;utente.
              </td>
              <td>365 giorni</td>
            </tr>
          </tbody>
        </table>

        <p>
          Il visitatore del sito ha comunque il diritto di opporsi alla raccolta ed elaborazione, per scopi statistici,
          di dati personali in accordo con l&rsquo;art. 89 paragrafo 1 del GDPR. Per esercitare il diritto sopra
          menzionato e altri diritti soggettivi, la persona interessata può scriverci in qualsiasi momento usando i dati
          di contatto forniti nell&rsquo;Impressum e/o nella Dichiarazione sulla privacy di questo sito.
        </p>

        <p>
          I cookie statistici utilizzati dal nostro sito sono legati al servizio di analisi web Google Analytics fornito
          da Google, Inc., di conseguenza per maggiori informazioni riguardo i cookie utilizzati per questo scopo
          consigliamo di consultare i {' '}
          <a href="https://www.google.com/analytics/terms/it.html" {...blankExternalLink}>
            termini di servizio di Google Analytics
          </a> e le {' '}
          <a href="https://www.google.com/analytics/learn/privacy.html" {...blankExternalLink}>
            Norme sulla privacy di Google Analytics
          </a>.

          Segnaliamo che su questo sito, il codice di Google Analytics <code>gat._anonymizeIp()</code> è
          stato integrato per garantire un indirizzo IP anonimo (cosiddetto IP-Masking).
        </p>

        <p>
          Puoi bloccare la raccolta di dati da parte di Google Analytics {' '}
          <a onClick={this.onGoogleAnalyticsOptOut}>cliccando qui</a>{
            doneGAOptOut && (<span className="icon has-text-success"><FaCheck /></span>)
          }.

          Un cookie &quot;opt-out&quot; sarà applicato al tuo sito e preverrà la raccolta di dati sulle visite a questo
          sito.
        </p>

        <p>
          La base giuridica per l&rsquo;uso dei cookie funzionali è il legittimo interesse all&rsquo;analisi,
          ottimizzazione e rimuneratività del nostro sito e dei nostri servizi, nel rispetto dell&rsquo;art. Art. 6 (1)
          (f) del GDPR.
        </p>

        <h3>Disattivazione ed eliminazione di tutti i cookie</h3>
        <p>
          Attraverso le impostazioni del browser, il visitatore può impedire gli venga richiesto il proprio consenso ad
          ogni accesso al sito o che i cookie siano automaticamente salvati sul suo dispositivo. Inoltre può eliminare
          in ogni momento i cookie attivi sul sito. Tutte le informazioni al riguardo sono reperibili sul supporto
          online del browser.
        </p>

        <p>
          La disattivazione dei cookie non limiterà il funzionamento del sito.
        </p>
      </div>
    )

    return (
      <StaticQuery query={query} render={render} />
    )
  }

  private onGoogleAnalyticsOptOut = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    this.setState({ doneGAOptOut: true })
  }
}
