import {
  graphql,
  StaticQuery,
} from 'gatsby'
import React from 'react'
import Helmet from 'react-helmet'

interface IJsonLDRenderProps {
  site: {
    siteMetadata: {
      title: string
      by: string
      description: string
      address: {
        countryCode: string
        locality: string
        region: string
        postalCode: string
        streetAddress: string
      }
      siteUrl: string
      contatti: {
        tel: string
        email: string
      }
    }
  }
  logo: {
    childImageSharp: {
      original: {
        src: string
      }
    }
  }
}

const query = graphql`query JsonLDQuery {
  site {
    siteMetadata {
      title
      by
      description
      siteUrl
      address {
        countryCode
        locality
        region
        postalCode
        streetAddress
      }
      contatti {
        tel
        email
      }
    }
  }
  logo: file(relativePath:{ eq: "logo.png"}) {
    childImageSharp {
      original {
        src
      }
    }
  }
}`

export default class JsonLD extends React.Component {
  // @see https://schema.org/LocalBusiness
  // @see https://search.google.com/structured-data/testing-tool/

  // TODO openingHours: check time range
  // TODO paymentAccepted, isicV4, knowsAbout, knowsLanguage
  // TODO vatID/taxID, sameAs (social)
  // TODO check legalName

  render() {
    const render = (data: IJsonLDRenderProps) => {
      const {
        site: { siteMetadata: meta },
        logo: { childImageSharp: { original: logo } },
      } = data
      const { contatti, address } = meta
      return (
        <Helmet>
          <script type="application/ld+json">{
            JSON.stringify({
              '@context': 'http://schema.org',
              '@type': 'LocalBusiness',
              'name': meta.title,
              'legalName': meta.title,
              'description': meta.description,
              'logo': meta.siteUrl + logo.src,
              'image': meta.siteUrl + logo.src,
              'founder': {
                '@type': 'Person',
                'name': meta.by,
              },
              'email': contatti.email,
              'telephone': contatti.tel,
              'address': {
                '@type': 'PostalAddress',
                'addressCountry': address.countryCode,
                'addressLocality': address.locality,
                'addressRegion': address.region,
                'postalCode': address.postalCode,
                'streetAddress': address.streetAddress,
              },
              'url': meta.siteUrl,
              'currenciesAccepted': 'EUR',
              'openingHours': 'Mo-Fr 08:30-12:30,14:00-16:00',
              'areaServed': {
                '@type': 'AdministrativeArea',
                'name': 'Italy'
              }
            })
          }
          </script>
        </Helmet>
      )
    }

    return (
      <StaticQuery query={query} render={render} />
    )
  }
}
